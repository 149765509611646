import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import workflowApolloClient from '@/lib/appsync/workflow';
import reportApolloClient from '@/lib/appsync/reportApolloClient';
import getJobsCount from '@/graphql/report/queries/getJobsCount.graphql';
import getActionTallies from '@/graphql/report/queries/getActionTallies.graphql';
import getJobCompletionPerc from '@/graphql/report/queries/getJobCompletionPerc.graphql';
import getDefermentByReason from '@/graphql/deferment/queries/getDefermentByReason.graphql';
import { DateTime } from 'luxon';
import { getNameByEmail } from '@/utils/users';
import defermentApolloClient from '@/lib/appsync/deferment';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'reportModule',
  store,
})
class ReportModule extends VuexModule {
  jobCount: any = [];

  actionTallies: any = [];

  jobCompletionPercentage: any = [];

  defermentByReason: any = [];

  @Mutation
  setJobCount(data): void {
    this.jobCount = data;
  }

  @Mutation
  setActionTallies(data): void {
    this.actionTallies = data;
  }

  @Mutation
  setJobCompletionPercentage(data): void {
    this.jobCompletionPercentage = data;
  }

  @Mutation
  setDefermentByReason(data): void {
    this.defermentByReason = data;
  }

  @Action({
    commit: 'setJobCount',
  })
  async getJobCount({ weekly = false }): Promise<any> {
    const {
      data: {
        get_tl_user_job_count: {
          tally_dict: tallyDict,
        },
      },
    } = await reportApolloClient.query({
      query: getJobsCount,
      variables: {
        input: {
          from_time: weekly ? DateTime.local().minus({ days: 7 }).startOf('day').toFormat("y-MM-dd'T'HH:mm:ss")
            : DateTime.local().startOf('day').toFormat("y-MM-dd'T'HH:mm:ss"),
          to_time: DateTime.local().toFormat("y-MM-dd'T'HH:mm:ss"),
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
      fetchPolicy: 'network-only',
    });

    const parsedDataset = jsonParse(tallyDict);

     return Object.entries(parsedDataset).map((i: any) => ({
      name: getNameByEmail(i[0]),
      // eslint-disable-next-line camelcase
      jobCount: i[1]?.job_count || 0,
      completed: i[1]?.completed || 0,
    }));
  }

  @Action({
    commit: 'setActionTallies',
  })
  async getActionTallies({ weekly = false}) {
    const {
      data: {
        get_actioned_tallies: {
          data,
        },
      },
    } = await reportApolloClient.query({
      query: getActionTallies,
      variables: {
        input: {
          from_time: weekly ? DateTime.local().minus({ days: 7 }).startOf('day').toFormat("y-MM-dd'T'HH:mm:ss")
            : DateTime.local().startOf('day').toFormat("y-MM-dd'T'HH:mm:ss"),
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
      fetchPolicy: 'network-only',
    });

    const parsedDataset = jsonParse(data);
    return Object.entries(parsedDataset);
  }

  @Action({
    commit: 'setJobCompletionPercentage',
  })
  async getJobCompletionPercentage({ weekly = false}) {
    const {
      data: {
        get_job_completion_percentage_by_period: {
          results,
        },
      },
    } = await workflowApolloClient.query({
      query: getJobCompletionPerc,
      variables: {
        input: {
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
          period: 'DAILY',
          from_datetime: weekly ? DateTime.local().minus({ days: 7 }).startOf('day').toFormat("y-MM-dd'T'HH:mm:ss")
            : DateTime.local().startOf('day').toFormat("y-MM-dd'T'HH:mm:ss"),
          to_datetime: DateTime.local().toFormat("y-MM-dd'T'HH:mm:ss"),
        },
      },
      fetchPolicy: 'network-only',
    });

    const parsedResults = results.map((i) => jsonParse(i));

    return {
      labels: parsedResults.map((i) => i.time),
      data: parsedResults.map((i) => Number(i.percent_complete * 100)),
    };
  }

  @Action({
    commit: 'setDefermentByReason',
  })
  async getDefermentByReason({ weekly = false}) {
    const {
      data: {
        get_deferment_by_reason: {
          dataset,
        },
      },
    } = await defermentApolloClient.query({
      query: getDefermentByReason,
      variables: {
        from_date: weekly ? DateTime.local().minus({ days: 7 }).startOf('day').toFormat("y-MM-dd'T'HH:mm:ss")
          : DateTime.local().startOf('day').toFormat("y-MM-dd'T'HH:mm:ss"),
        to_date: DateTime.local().toFormat("y-MM-dd'T'HH:mm:ss"),
        operator: getConfigEnv('OPERATOR_LOWERCASED'),
      },
      fetchPolicy: 'network-only',
    });

    const parsedDataset = JSON.parse(dataset);
    const time = parsedDataset.map((i) => i.time);
    const deferment = parsedDataset.map((i) => i.Deferment);
    const reasons = parsedDataset[0].Reason;

    return {
      time,
      datasets: reasons.map((i, idx) => ({
        label: '',
        backgroundColor: ['#4BDF95', '#303279', '#FFD000', '#006CFF', '#4BDF95', '#303279', '#FFD000'][idx],
        borderColor: ['#4BDF95', '#303279', '#FFD000', '#006CFF', '#4BDF95', '#303279', '#FFD000'][idx],
        borderWidth: 1,
        data: time.map((t, tidx) => deferment[tidx][idx]),
      })),
    };
  }
}

export default getModule(ReportModule);
