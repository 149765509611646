import AWSAppSyncClient from 'aws-appsync';
import VueApollo from 'vue-apollo';
import { getAccessToken } from '@/lib/auth';
import * as localForage from 'localforage';
import { getConfigEnv } from '@/utils/helpers';

const client = new AWSAppSyncClient({
  url: getConfigEnv('REPORT_ENDPOINT_URL'),
  region: getConfigEnv('APPSYNC_REGION'),
  auth: {
    type: 'AMAZON_COGNITO_USER_POOLS',
    jwtToken: async () => getAccessToken(),
  },
  offlineConfig: {
    storage: localForage,
    keyPrefix: 'report',
  },
}, {
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
  },
});

const appSyncProvider = new VueApollo({
  defaultClient: client,
});
const reportApolloClient = appSyncProvider.defaultClient;

export default reportApolloClient;
