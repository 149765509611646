import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import predictionsApolloClient from '@/lib/appsync/predictions';
import getCycleTimeMetrics from '@/graphql/predictions/queries/getCycleTimeMetrics.graphql';
import { DateTime } from 'luxon';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'analysisModule',
  store,
})
class AnalysisModule extends VuexModule {
  cycleTimeChart: any = null;

  cycleTimeChartOptions: any = [];

  @Mutation
  setCycleTimeChart(data) {
    this.cycleTimeChart = data;
  }

  @Mutation
  setCycleTimeChartOptions(data) {
    this.cycleTimeChartOptions = data;
  }

  @Action
  async getCycleTimeChart(): Promise<any> {
    const { data: { cycle_time_metrics: results } } = await predictionsApolloClient.query({
      query: getCycleTimeMetrics,
      variables: {
        operator: 'PDC',
        from_date: DateTime.local().minus({ days: 30 }).startOf('day').toFormat("y-MM-dd'T'HH:mm:ss"),
        to_date: DateTime.local().toFormat("y-MM-dd'T'HH:mm:ss"),
      },
      fetchPolicy: 'network-only',
    });

    const parsedDataset = JSON.parse(results.dataset || null);

    const labels = parsedDataset.time[0];
    const wells = parsedDataset.Value[0];
    const cycleTimeSmoothed = parsedDataset.Value[0].map((i, idx) => ({
      y: i,
      x: labels[idx],
    }));

    const cycleTimeChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 0,
        },
        legend: {
          display: false,
        },
        hover: {
          mode: 'point',
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
        tooltips: {
          enabled: true,
          displayColors: false,
          backgroundColor: '#fff',
          titleFontColor: '#8C8FB0',
          bodyFontColor: '#000750',
          bodyFontStyle: 'bold',
          titleAlign: 'center',
          bodyAlign: 'center',
          xPadding: 10,
          yPadding: 10,
          filter: (tooltipItem) => tooltipItem.datasetIndex !== 0,
          callbacks: {
            title: () => '',
            label: (tooltipItems) => wells[tooltipItems.index],
          },
        },
        scales: {
          xAxes: [{
            type: 'time',
            ticks: {
              fontColor: '#FFFFFF',
              fontStyle: 'bold',
              maxTicksLimit: 20,
              callback: (tick, index) => (index % 3 ? '' : tick),
              maxRotation: 0,
            },
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MM/DD',
              },
            },
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            ticks: {
              min: 0,
              fontColor: '#FFFFFF',
              fontStyle: 'bold',
            },
            gridLines: {
              display: false,
            },
          }],
        },
        plugins: {
          zoom: false,
        },
      };

    this.setCycleTimeChart(cycleTimeSmoothed);

    this.setCycleTimeChartOptions(cycleTimeChartOptions);
  }
}

export default getModule(AnalysisModule);
